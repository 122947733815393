import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import style from "./styles.module.css"

const Header = () => (
  //puede recibir como props "siteTitle"
  <header>
    <div>
      <ul className={style.list}>
        <li className={style.itemList} style={{float:"left"}}>
          <Link to="/" className={style.itemListContent}>
            [Logo del sitio]
          </Link>
        </li>
        <li className={style.itemList}>
          <Link className={style.itemListContent} to="/">
            Home
          </Link>
        </li>

        {/* <li className={style.itemList}>
          <div className={style.dropdown}>
            <button className={style.dropbtn}>Planes</button>
            <div className={style.dropdownContent}>
              <a style={{ display: "block" }} href="/">
                Link 1
              </a>
              <a style={{ display: "block" }} href="/">
                Link 2
              </a>
              <a style={{ display: "block" }} href="/">
                Link 3
              </a>
            </div>
          </div>
        </li> */}

        <li className={style.itemList}>
          <Link className={style.itemListContent} to="/">
            Contact
          </Link>
        </li>
        <li className={style.itemList}>
          <Link className={style.itemListContent} to="/">
            About
          </Link>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
